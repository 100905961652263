/**
 * Mobile Navigation Animation
 * 
 * @package cthx
 */

(function($) {
	$(document).ready(function() {

		// elements and heights
		var header   = $( 'header[role=banner]' );
		var nav      = $( 'nav', header );
		var adminBar = $( '#wpadminbar' );


		/**
		 * Calculate menu offset
		 */
		function getOffset() {
			return header.outerHeight() + adminBar.outerHeight();
		}

		/**
		 * Recalculate menu offset on resize
		 */
		$( window ).resize( function(){

			if ( nav.hasClass( 'toggled' ) ) {
				nav.css({ top: getOffset() });
			}
		});


		/**
		 * Toggler: toggle class and position menu
		 */
		$( '.menu-toggle' ).click( function(){

			nav.toggleClass( 'toggled' );

			// set offset
			if ( nav.hasClass( 'toggled' ) ) {
				nav.css({ top: getOffset() });
			} else {
				nav.css({ top: -getOffset() + -nav.outerHeight() });
			}
		});

		
		/**
		 * Add submenu dropdown toggles
		 */
		$( '.menu-item-has-children' ).append(
			'<button class="toggle-sub"><span class="screen-reader-text">Menu</span></button>'
		);

		// on toggle click
		$( '.toggle-sub', '.menu-item-has-children' ).click( function(){
			var li = $(this).parent();
			li.toggleClass( 'sub-toggled' );
		});

	}); // document.ready
})(jQuery);