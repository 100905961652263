/**
 * Javascript snippets for in-form functionality
 * 
 * @package cthx
 */

(function($) {
	$(document).ready(function() {

		// add class when leaving field (if filled)
		$( 'form input' ).on( 'blur', function() {

			if ( $(this).val() !== "" ) {
				$(this).addClass( 'filled' );
			} else {
				$(this).removeClass( 'filled' );
			}
		});

	}); // document.ready
})(jQuery);